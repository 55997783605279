<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    :scrollable="false"
    transition="dialog-bottom-transition"
  >
  <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        :x-small="!largeButton"
        color="color3 color3Text--text"
        :text="!largeButton"
        :class="largeButton ? null : 'ma-0 pa-0'"
      >Open Score board display</v-btn>
    </template>
    <v-card style="height: 100%; max-height: 100%" :dark="dark" class="pa-1 d-flex" tile :key="`${dialog}`" v-if="dialog">
      <div style="width: 15%; min-height: 100%; max-height: 100%" class="elevation-3 white">
        <div v-for="img in images" :key="img" class="d-flex justify-center align-center ad-img">
          <img
            :src="img"
            style="max-width: 90%; max-height: 100%;"
          >
        </div>
      </div>
      <div style="width: 85%; min-height: 100%">
        <div style="width: 100%; height: 24px" class="black white--text d-flex">
          <div class="text-center" style="min-width: 7%">CT</div>
          <div style="min-width: 66%"></div>
          <div class="text-center" style="min-width: 9%">Set 1</div>
          <div class="text-center" style="min-width: 9%">Set 2</div>
          <div class="text-center" style="min-width: 9%">Set 3</div>
        </div>
        <div style="width: 100%; height: calc(100% - 24px)">
          <div v-for="(match, i) in matches" :key="i" style="height: 20%">
            <div style="height: calc(50%); width: 100%;" class="d-flex" :class="{ winner: match.home.winner, home: !dark, 'home-dark': dark}">
              <div class="ct1 d-flex align-end justify-center" style="min-width: 7%"></div>
              <div class="team d-flex align-center justify-start" style="min-width: 66%">
                <div class="white d-flex justify-center align-center" style="width: 10%; min-width: 10%; height: 100%">
                  <img
                    v-if="match.home.logo"
                    :src="match.home.logo"
                    style="max-width: 100px; max-height: 100px;"
                  >
                </div>
                <div class="ml-3 bn text-truncate" :class="{ 'text-uppercase': upper }">
                  {{match.home.players ? match.home.players.name : null}}
                </div>
              </div>
              <div class="score d-flex align-center justify-center" style="min-width: 9%">{{gameScore(match, 0, true)}}</div>
              <div class="score d-flex align-center justify-center" style="min-width: 9%">{{gameScore(match, 1, true)}}</div>
              <div class="score d-flex align-center justify-center" style="min-width: 9%">{{gameScore(match, 2, true)}}</div>
            </div>
            <div style="height: calc(50%); width: 100%;" class="d-flex away" :class="{ winner: match.away.winner}">
              <div class="ct d-flex align-start justify-center" style="min-width: 7%">{{ match.court }}</div>
              <div class="team d-flex align-center justify-start" style="min-width: 66%">
                <div class="white d-flex justify-center align-center" style="width: 10%; min-width: 10%; height: 100%">
                  <img
                    v-if="match.away.logo"
                    :src="match.away.logo"
                    style="max-width: 100px; max-height: 100px;"
                  >
                </div>
                <div class="ml-3 text-truncate" :class="{ 'text-uppercase': upper }">
                  {{match.away.players ? match.away.players.name : null}}
                </div>
              </div>
              <div class="score d-flex align-center justify-center" style="min-width: 9%">{{gameScore(match, 0, false)}}</div>
              <div class="score d-flex align-center justify-center" style="min-width: 9%">{{gameScore(match, 1, false)}}</div>
              <div class="score d-flex align-center justify-center" style="min-width: 9%">{{gameScore(match, 2, false)}}</div>
            </div>
          </div>
        </div>
      </div>
      <v-btn
        color="grey lighten-4"
        class="mt-n3 ml-n3"
        text icon absolute left top
        x-small
        @click.stop="dialog = false"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
      <v-card-actions class="justify-start pa-0" v-if="false">
        <v-btn
          color="grey"
          text icon absolute left bottom
          @click.stop="dark = !dark"
        >
          <v-icon>fa{{dark ? 'r' : 's'}} fa-sun</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'
import moment from 'moment'

export default {
  props: ['largeButton', 'courts', 'dark', 'upper'],
  data () {
    return {
      refreshKey: 0,
      dialog: false,
      // courts: [1, 6, 7, 8, 9],
      myTimeout: null,
      images: [
        'https://vblimages.blob.core.windows.net/images/c57a5f91-58c1-4c8b-ba5f-3ba8739ad313.png',
        'https://vblimages.blob.core.windows.net/images/cb10c5a2-24cc-4adc-9013-889a17c2416d.png',
        'https://vblimages.blob.core.windows.net/images/38f89297-0ca6-4d64-a351-ba158fb0254e.jpg',
        'https://vblimages.blob.core.windows.net/images/71a20bb2-cd80-4459-b375-4c6677d91283.png',
        'https://vblimages.blob.core.windows.net/images/8860cdbf-d45a-4593-b284-504ec119f18a.png'
      ],
      imagesx: []
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    matches () {
      const teams = this.tournament.activeTeams
      this.callRefresh()
      if (this.refreshKey > -1) {
        return this.displayMatches.map(m => {
          return {
            court: m ? m.court : null,
            games: m ? m.games : [],
            home: {
              players: m ? m.getLineupPlayers(true, teams, false, 2, true) : null,
              logo: m ? m.getLogo(true, teams) : null,
              winner: m && m.winner === 'home'
            },
            away: {
              players: m ? m.getLineupPlayers(false, teams, false, 2, true) : null,
              logo: m ? m.getLogo(false, teams) : null,
              winner: m && m.winner === 'away'
            }
          }
        })
      }
      return false
    },
    displayMatches () {
      return this.courts && this.refreshKey > -1 ? this.courts.map(c => {
        const base = this.tournament.publicMatches.filter(f => f.court === `${c}`).sort(firstBy('unix', -1))
        const started = base.find(f => f.status === 'Started')
        const complete = base.find(f => f.status === 'Complete')
        base.reverse()
        const upcoming = base.find(f => !f.status)
        if (started) {
          if (complete && started.unix > complete.unix) {
            return started
          }
          return started
        }
        if (complete) {
          const now = moment()
          const twoMinutesAgo = now.subtract(2, 'm').valueOf()
          const lastModified = +complete.dtModified
          if (lastModified > twoMinutesAgo || !upcoming) {
            return complete
          }
        }
        return upcoming
      }) : []
    }
  },
  methods: {
    openFullscreen () {
      const elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen()
      }
    },
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen()
      }
    },
    callRefresh () {
      if (this.myTimeout) clearTimeout(this.myTimeout)
      this.myTimeout = setTimeout(this.incrementKey, 10000)
    },
    incrementKey () {
      this.refreshKey++
    },
    gameScore (match, i, home) {
      if (!match || !match.games || match.games.length <= i) return null
      const game = match.games[i]
      var result = home ? game.home : game.away
      if (result) return result
      if (game.status) return 0
      return null
    }
  },
  watch: {
    dialog: function (v) {
      if (v) {
        this.openFullscreen()
      } else {
        this.closeFullscreen()
      }
    }
  },
  destroyed () {
    if (this.myTimeout) clearTimeout(this.myTimeout)
  }
}
</script>

<style scoped>

.home div:not(:first-child) {
  border-bottom:1px solid rgba(0, 0, 0, 0.3);
}
.home-dark div:not(:first-child) {
  border-bottom:1px solid rgba(255, 255, 255, 0.3);
}
.bn {
  border-bottom: 0 !important
}
.away {
  border-bottom:2px solid #05abc4;
}
.ct1 {
  font-size: 1.75rem;
}
.ct {
  font-size: 6rem;
  margin-top: -75px;
}
.team {
  font-size: 4.5rem;
  line-height: 1.1;
}
.score {
  font-size: 6.5rem;
  line-height: 1;
}
.winner {
  font-weight: 700;
}
.ad-img {
  height: 20%;
  max-height: 20%
}

@media (max-width: 1280px) {
  .ad-img {
    height: 20%;
    max-height: 20%
  }
  .team {
    font-size: 3.5rem;
    line-height: 1;
  }
  .score {
    font-size: 4.5rem;
    line-height: 1;
  }
  .ct {
    font-size: 4rem;
    margin-top: -50px;
  }
  .text-uppercase {
    font-size: 3rem !important;
  }
}
</style>
